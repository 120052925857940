import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link as Scroll } from 'react-scroll';
import { Helmet } from 'react-helmet'
import { Link, graphql, useStaticQuery } from 'gatsby';
import PortfolioContext from '../../context/context';


import BackgroundImage from 'gatsby-background-image'







const HeroTest = () => {
    const { hero } = useContext(PortfolioContext);
    const { sectionId, title, name, subtitle, navigation, message, helmet, image, classname } = hero;
    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);


    const data = useStaticQuery(
        graphql`
      query {
        firstImg: file(relativePath: { eq: "lift.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        secondImg: file(relativePath: { eq: "woman-lifting.jpg" }) {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }



      }
    `
    )
    let heroImage = null;
    switch (image) {
        case "home":
            heroImage = data.firstImg.childImageSharp.fluid;
            break;
        case "premium":
            heroImage = data.secondImg.childImageSharp.fluid;
            break;
        default:
            return null;
    }

    // Set ImageData.
    //        const imageData = data.desktop.childImageSharp.fluid

    return (


        <BackgroundImage id="heroTop" className="hero jumbotron"
            Tag="section"
            fluid={heroImage}
            style={{
                backgroundColor: 'black',
                backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100vh',
                display: 'flex',
margin: '0'            }}
        >

            <section id={sectionId} className={classname}>



                <Helmet title={`365 Nutrition | ${helmet}`} />
                <Container>


                    <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
                        <div className="hero-header">
                            {navigation && navigation.map((menu) => {


                                const { title, url, local } = menu;
                                return (
                                    //               <div>
                                    //              <span>
                                    //                     {backButton ? <Link className="cta-btn cta-btn--hero" href={url} onClick={() => this.props.history.goBack}>Back</Link> : ''}
                                    //             </span>
                                    <span >


                                        { local ? <Scroll className="cta-btn cta-btn--hero" to={url} smooth duration={1000}>
                                            {title}
                                        </Scroll> : <Link className="cta-btn cta-btn--hero" href={url}  >
                                                {title}
                                            </Link>}

                                    </span>
                                    //          </div>
                                );
                            })}
                        </div>

                    </Fade>

                    <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">

                        <h1 className="hero-title">
                            {title}{' '}
                            <span className="text-color-main">{name || ''}</span>
                            <br />
                            <p> {subtitle}</p>
                        </h1>
                    </Fade>
                    <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
                        <div className="badges">


                            <p>{message}</p>

                        </div>
                    </Fade>

                </Container>
            </section>
        </BackgroundImage>

    )
}

export default HeroTest;