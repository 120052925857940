import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '365 Nutrition', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: 'Welcome to 365 Nutrition', // e.g: Welcome to my website
};


// HERO DATA
export const heroData = {
    sectionId: 'hero',
    title: '365 Nutrition',
    helmet:'',
    name: '',
    subtitle: "It's A Lifestyle",
    cta: '',
    message: 'Coming Soon to Android and iOS',
    image: "home",
    classname: "hero",
    backButton: false,
    navigation: [
        {
            title: 'Features',
            url: 'about',
            local: true,
        },
        {
            title: 'Premium',
            url: '/premium',
            local: false,

        },
        {
            title: 'Blog',
            url: '/blog',
            local: false,
        },
        {
            title: 'Contact',
            url: 'contact',
            local: true,
        }
    ]
};

// ABOUT DATA
export const aboutData = {
  img: 'Collage.png',
  paragraphOne: 'Make healthy and nutritious meals part of your every day lifestyle with this easy to use personalised meal planner. Kick back and enjoy the spare time, as 365 Nutrition automatically generates your grocery list based on your personalised meal plans.',
  paragraphTwo: '',
  paragraphThree: '',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'Recipes.png',
    title: 'Recipes',
    info: 'Food should be fun, not complicated or stressful. Select from our free collection of healthy, nutritious recipes and put the fun back into cooking.',
    info2: '',
    url: '',
   
  },
  {
    id: nanoid(),
    img: 'MealPlan.png',
    title: 'Meal Plan',
    info: "Plan your meals for the week using our extensive collection of ingredients and recipes. Know exactly what you're putting in your body.",
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Groceries.png',
    title: 'Groceries',
      info: 'Save time and money with automated grocery lists created straight from your weekly meal plan.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'facebook',
          url: 'https://www.facebook.com/people/365-Nutrition/100066942697557/',
    },
    {
      id: nanoid(),
      name: 'instagram',
        url: 'https://www.instagram.com/365__nutrition/',
    },
    {
      id: nanoid(),
      name: 'youtube',
        url: 'https://www.youtube.com/channel/UCcKthC6ZA5XmjjgnMBtXgjg',
    },
   
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
