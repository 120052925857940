import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

import { Link } from 'react-scroll';
import GithubButtons from '../GithubButtons/GithubButtons';

import { githubButtons } from '../../mock/data';


const Footer = () => {
    const { footer } = useContext(PortfolioContext);
    const { networks } = footer;
    const { isEnabled } = githubButtons;

    return (
        <section id="contact">
            <Container>
                <Fade bottom duration={1000} delay={800} distance="30px">
                    <Title title="Follow Us" />

                    <span className="back-to-top">
                        <Link to="heroTop" smooth duration={1000}>
                            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
                        </Link>
                    </span>
                    <div className="social-links">
                        {networks &&
                            networks.map((network) => {
                                const { id, name, url } = network;
                                return (
                                    <a
                                        key={id}
                                        href={url || 'https://github.com/cobidev/gatsby-simplefolio'}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        aria-label={name}
                                    >
                                        <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                                    </a>
                                );
                            })}
                    </div>
                    <hr />
                    <p className="footer__text">
                        © {new Date().getFullYear()} - 365 Nutrition
                    </p>
                </Fade>
            </Container>
        </section>
    );
};

export default Footer;
